import "./raceHistoryGroups.scss";
import { BoardTable } from "../../../../components/ScoreboardTable/ScoreboardTable";
import { useState, useEffect } from "react";
import { parseDate } from "../../../../helpers/Parsers";
import { useNavigate } from "react-router-dom";

interface RaceHistoryData {
    id: number;
    event_name: string;
    event_track_name: string;
    event_date: string;
    event_country: string;
    event_sport: number;
}

export interface RaceHistoryElement {
    data: RaceHistoryData[];
    current_page: number;
};


export const RaceHistoryGroups = (props: any) => {

    const navigate = useNavigate();

    const [data, setData] = useState<any>(null);
    const [nameFilterValue, setNameFilterValue] = useState<string>("");
    const [dateFilterValue, setDateFilterValue] = useState<string>("desc");

    const navigateTo = (item: any) => {
        navigate(`/history/${item.date}/${item.track_name}`);
    };

    const handleMessage = (event: any) => {
        const message = JSON.parse(event.data);
        switch (message.type) {
            case "event_history_response": {
                message.resp?.data?.forEach((item: any) => {
                    item.parsed_date = parseDate(item?.date);
                });
                setData(message.resp);
                break;
            }
            default: console.log("No event provided!");
        }
    }

    useEffect(() => {
        props.ws.send(JSON.stringify({ type: "event_history" }));
        props.ws.addEventListener('message', handleMessage);
        return () => {
            props.ws.removeEventListener('message', handleMessage);
        }
    }, [props.ws]);

    const handlePageChange = (page: number) => {
        if (page > data?.max_pages || page < 1) return;
        props.ws.send(JSON.stringify({ type: "event_history", page: page, championship: nameFilterValue, date: dateFilterValue}));
    }

    const handleChampoinshipChange = (c: any) => {
        props.ws.send(JSON.stringify({ type: "event_history", championship: c.name }));
    }

    const handleDateChange = (date: any) => {
        props.ws.send(JSON.stringify({ type: "event_history", championship: nameFilterValue, date: date}));
        setDateFilterValue(date);
    }

    return (
        <div
            className="app__historyGroups_main"
        >
            <header>
                <h3>Event History</h3>
                <p>Browse unofficial results from all previous races.</p>
            </header>
            <BoardTable
                legend={[
                    {data: "championship", dataParent: "", name: "EVENT NAME", width: 50, minWidth: 250, img: false, center: false, customSrc: "", customElement: ""},
                    {data: "track_name", dataParent: "", name: "TRACK NAME", width: 35, minWidth: 150, img: false, center: false, customSrc: "", customElement: "", color: "#9baecb"},
                    {data: "parsed_date", dataParent: "", name: "DATE", width: 15, minWidth: 120, img: false, center: false, customSrc: "", customElement: ""}
                ]}
                data={{}}
                properties={{
                    filters: true, navHistory: true, pageSwitch: true, maxPage: data?.max_pages,
                    dateFilterValue: dateFilterValue, nameFilterValue: nameFilterValue,
                    currentPageData: data?.data, currentPage: data?.page
                }}
                functions={{currentPage: handlePageChange,
                            setNameFilterValue: setNameFilterValue, setDateFilterValue: handleDateChange
                }}
                customFunctions={{filters: handleChampoinshipChange, navigateTo: navigateTo}}
            />
        </div>
    );
}
