import React from "react";
import { ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css";
import { classToColor } from "../../helpers/Functions";
import isEqual from "lodash/isEqual";
import debounce from 'lodash/debounce';

export default class ResizableTable extends React.Component
    <{
        data: any, fields: any, fontSize: number, colors: any[], mediaQuery: any, resultsByClass: boolean,
        colordersName: string, colwidthsName: string
    },
    {
        data: any,
        fields: any,
        enabledFieldsInitialized: boolean,
        colwidths: any,
        groupedData: any,
        groupedDataKeys: string[],
        currentChildWidth: number,
        dragging: boolean,
        dragIndex: number,
        dragItem: string,
        originalDragItem: string,
        selectedCompetitors: string[];
    }
    > {

    private debouncedUpdate: (prevProps: Readonly<{ data: any; fields: any; }>) => void;
    private verticalModeDataType: any;

    // Variables
    fields: any;
    ref: any;
    data: any;
    colordersName: string;
    colwidthsName: string;
    defaultColWidth: number = 120;
    maxTableWidth: number = 1600;
    enabledFieldsInitialized: boolean = false;
    loadedPreviousFieldWidths: boolean = false;
    colWidthsFirstInit: boolean = true;
    defaultTableHeight: number = 570;
    firstOfEachClass: any;
    settings:any = {
        currentColumnWidth: {}
    };


    constructor(props: any) {
        super(props);
        this.state = {
            data: {},
            fields: [],
            enabledFieldsInitialized: false,
            colwidths: {},
            groupedData: {},
            groupedDataKeys: [],
            currentChildWidth: 0,
            dragging: false,
            dragIndex: -1,
            dragItem: "",
            originalDragItem: "",
            selectedCompetitors: []
        };
        this.fields = props.fields;
        this.data = props.data;
        this.ref = React.createRef();
        this.colordersName = props.colordersName;
        this.colwidthsName = props.colwidthsName;
        this.verticalModeDataType = this.determineVerticalMode();


        this.debouncedUpdate = debounce((prevProps: Readonly<{ data: any; fields: any; }>) => {
            this.fields = this.props.fields;
            this.fields.order = prevProps.fields.order;
            this.data = this.props.data;
            if (!this.enabledFieldsInitialized) {
                this.enabledFieldsInitialized = true;
            }
            this.importData(this.props.data);
        }, 100);
    };

    componentDidMount(): void {
        this.animate();
        const selectedCompetitors = JSON.parse(localStorage.getItem("selected_start_nr") || '[]');
        this.setState({ selectedCompetitors });
    }

    animate() {
        requestAnimationFrame(this.animate.bind(this));
    }

    determineVerticalMode() {
        if (this.props.fields.verticalModeDefaults) {
            if (this.props.data?.race_info?.race_type_id === 2) {
                return this.props.fields.verticalModeDefaults.raceMode;
            } else {
                return this.props.fields.verticalModeDefaults.qualificationMode;
            }
        }
        return {};
    }

    addToLocalStorage(nr: number) {
        const current = localStorage.getItem("selected_start_nr");
        if (!current) {
            localStorage.setItem("selected_start_nr", JSON.stringify([nr]));
        } else {
            const parsed = JSON.parse(current);
            const index = parsed.indexOf(nr);
            if (index > -1) {
                parsed.splice(index, 1);
            } else {
                parsed.push(nr);
            }
            localStorage.setItem("selected_start_nr", JSON.stringify(parsed));
        }
        const selectedCompetitors = JSON.parse(localStorage.getItem("selected_start_nr") || '[]');
        this.setState({ selectedCompetitors });
    }

    swapStrings(arr: string[], str1: string, str2: string) {
        const index1 = arr.indexOf(str1);
        const index2 = arr.indexOf(str2);

        if (index1 !== -1 && index2 !== -1) {
            [arr[index1], arr[index2]] = [arr[index2], arr[index1]];
        } else {
            console.log("One or both strings not found in the array.");
        }
        return arr;
    };

    handleDragStartEnd = (index: number, item: string) => {
        if (this.state.dragging) {
            this.setState({ dragging: false, dragIndex: -1 });
        } else {
            this.setState({ dragging: true, dragIndex: index, dragItem: item, originalDragItem: item});
        }
    };

    handleDrag = (item: string) => {
        if (this.state.dragging && item !== this.state.dragItem) {
            const newIndex = this.state.groupedDataKeys.findIndex((x: any) => x === item);
            this.setState({ dragItem: item, dragIndex: newIndex, fields: {
                ...this.state.fields,
                order: this.swapStrings([...this.state.fields.order], this.state.originalDragItem, item)
            }});
            this.fields.order =  this.swapStrings([...this.fields.order], this.state.originalDragItem, item);
            localStorage.setItem(this.colordersName, JSON.stringify(this.fields.order));
            this.importData(this.data);
        }
    };

    componentDidUpdate(prevProps: Readonly<{ data: any; fields: any; }>): void {
        if (this.props.data !== prevProps.data || !isEqual(this.props.fields, prevProps.fields)) {
            // Debounce the data update
            this.debouncedUpdate(prevProps);
        }
    }

    getFirstEntryForEachClass(data: any) {
        const entries: any = {};
        data?.competitor?.forEach((obj: any) => {
            if (!(obj.class in entries)) {
                entries[obj.class] = obj.nr;
            }
        });
        const firstEntries = Object.values(entries);
        return firstEntries || [];
    }
    importData(data: any): void {
        const columnWidthData: any = {};
        const groupedData: any = {};
        if (!this.loadedPreviousFieldWidths) {
            this.settings.currentColumnWidth = JSON.parse(localStorage.getItem(this.colwidthsName) || "{}");
            this.setState({
                fields: {
                    ...this.state.fields,
                    order: JSON.parse(localStorage.getItem(this.colordersName) || JSON.stringify(this.fields.order))
                }
            })
            this.fields.order = JSON.parse(localStorage.getItem(this.colordersName) || JSON.stringify(this.fields.order));
            this.loadedPreviousFieldWidths = true;
        }
        data?.competitor?.forEach((item: any) => {
            for (const [key, value] of Object.entries(item)) {
                // skips
                if (Array.isArray(this.fields.enabled)) {
                    if (!this.fields.enabled.includes(key)) {
                        continue;
                    }
                } else {
                    if (typeof this.fields.enabled === 'object') {
                        if (!Object.keys(this.fields.enabled).includes(key)) {
                            continue;
                        }
                    }
                }
                if (this.fields.banned.includes(key)) { continue; }

                if (groupedData.hasOwnProperty(key)) {
                    groupedData[key].push(value);
                } else {
                    groupedData[key] = [];
                    groupedData[key].push(value);
                    if (!this.settings.currentColumnWidth?.[key]) {
                        this.settings.currentColumnWidth[key] = this.fields.config[key]?.defaultWidth || this.defaultColWidth;
                    }
                    columnWidthData[key] = this.settings.currentColumnWidth[key] || this.fields.config[key]?.defaultWidth || this.defaultColWidth;
                }
            };
        });
        this.verticalModeDataType = this.determineVerticalMode();

        if (this.props.resultsByClass) {
            this.firstOfEachClass = this.getFirstEntryForEachClass(data);
        }
        const sorted = [...Object.keys(groupedData)].sort((a, b) => {
            const aIndex = this.fields.order.indexOf(a);
            const bIndex = this.fields.order.indexOf(b);
            return aIndex - bIndex;
        });
        const sum: number = Object.values(columnWidthData).reduce<number>((partial: any, a) => partial + a, 0 as number);
        this.setState({
            groupedData: groupedData,
            groupedDataKeys: sorted,
            colwidths: columnWidthData,
            currentChildWidth: sum
        });
    }
    getPositionByClass(position: any) {
        const found = this.props?.data?.competitor?.find((obj: any) =>
            obj.position === position
        );
        if (!found) { return position; }
        return found.position_in_class;
    }
    parseHeader(item: string, index: number) {
        let label = item;
        if (this.state.groupedDataKeys.includes(item)) {
            label = this.fields.config[item]?.label;
        }
        return ( <p
            onClick={(event: any) => this.handleDragStartEnd(index, item)}
            onMouseMove={(event: any) => this.handleDrag(item)}
            >{label}</p> );
    }
    parseItem(item: string, grouped: string, index: number) {
        if (Object.keys(this.fields.config).includes(item)) {
            const element = this.fields.config[item]?.customElement;
            if (typeof element === 'string' || typeof element === 'function') {
                const props: any = {
                    name: item,
                    data: grouped,
                    index: index,
                    fullData: this.data?.competitor?.[index],
                    byClass: this.props.resultsByClass,
                    fastest: this.data?.race_info?.fastest,
                    race_info: this.data?.race_info,
                    getPosByClass: this.getPositionByClass
                };
                if (item === "position") {
                    props.resultsByClass = this.props.resultsByClass;
                    props.resultsByClassPosAlt = this.getPositionByClass(grouped);
                }
                return React.createElement(element, props, grouped);
            }
        }
        const fontSize = this.fields.config[item]?.fontSize;
        return ( <p style={{fontSize: fontSize ? `${fontSize * this.props.fontSize}px` : `${20 * this.props.fontSize}px`}}>{grouped || ""}</p> );
    }
    handleResize(colkey: any, width: number) {
        const sum = this.state.currentChildWidth;
        this.settings.currentColumnWidth[colkey] = width;
        localStorage.setItem(this.colwidthsName, JSON.stringify(this.settings.currentColumnWidth));
        this.setState({
            colwidths: {...this.state.colwidths, [colkey]: width},
            currentChildWidth: Number(sum - this.state.colwidths[colkey] + width)
        });
    }
    print(what: any) {
        console.log(what);
        return true;
    }
    render(): any {

        if (!this.props.data) {
            return (
                <div className={
                    this.props.mediaQuery.mobile ? "mobile__historyRace-table-inside-loader" : "app__historyRace-table-inside-loader"
                }>
                    <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                    <p>Loading competitor data...</p>
                </div>
            );
        }

        return (
        <div
            className={
                this.props.mediaQuery.mobile ?
                "app__historyRace-table-inside-mobile"
                :
                "app__historyRace-table-inside"
            }
            ref={this.ref}
        >
                <div className={
                    this.props.mediaQuery.mobile ?
                    "resizable_wrapper_mobile" :
                    "resizable_wrapper"
                }>
                {
                    ((!this.props.mediaQuery.portrait && this.props.mediaQuery.mobile) ||
                    (!this.props.mediaQuery.mobile))
                    ?
                    this.state.groupedDataKeys.map((item: any, index: number) =>
                        <>
                        {
                            index === this.state.dragIndex
                            ?
                                <div
                                    className="resizable_ghost_block"
                                    style={{width: this.state.colwidths[item]}}
                                    onClick={(event: any) => this.handleDragStartEnd(index, item)}
                                >
                                    {this.parseHeader(item, index)}
                                </div>
                            :
                            !this.state.groupedData[item].every((element: any) => element === null) &&
                            <ResizableBox
                                className="resizeable_table_head"
                                key={`resizeable_item_head${index}`}
                                width={this.state.colwidths[item]}
                                height={30}
                                axis="x"
                                minConstraints={[75, 30]}
                                maxConstraints={[300, 30]}
                                onResize={(e, {size}) => {
                                    this.handleResize(item, size.width);
                                }}
                            >
                                {this.parseHeader(item, index)}
                            </ResizableBox>
                        }
                        </>
                    )
                    :
                    Object.keys(this.verticalModeDataType)?.map((item: any, index: number) =>
                        <div
                            className="non-resizable_table_head"
                            key={`non-resizeable_item_head${index}`}
                            style={{width: `${this.verticalModeDataType[item].width}`}}
                        >
                            {this.parseHeader(this.verticalModeDataType[item]?.shortname || item, index)}
                        </div>
                    )
                }
                </div>
            <div
                className="resizeable_content-wrapper"

                id="resizable_content-wrapper-id"
                style={{display: this.props.mediaQuery.mobile && this.props.mediaQuery.portrait ? "block" : "flex"}}
            >
                {
                this.props.mediaQuery.mobile && this.props.mediaQuery.portrait ?
                this.data?.competitor?.map((item: any, index: number) =>
                    <>
                        {
                            this.props.resultsByClass &&
                            this.firstOfEachClass &&
                            this.firstOfEachClass.includes(this.props.data?.competitor?.[index]?.nr) &&
                            <div
                                className="resiazble_class_header"
                                style={{
                                    width: "100%",
                                    height: "50px",
                                    borderBottom: `2px solid ${classToColor(this.props.data?.competitor?.[index]?.class, this.props.data?.race_info?.availableClasses)}`
                                }}
                            >
                                {this.props.data?.competitor?.[index]?.class || ""}
                            </div>
                        }
                        <div
                            className={`mobile__resiazble_content
                            ${
                                this.props.data?.competitor?.[index]?.flash_status ?
                                this.props.data?.competitor?.[index]?.flash_status === "+" ?
                                "flash_resizable_item_green"
                                :
                                this.props.data?.competitor?.[index]?.flash_status === "-" ?
                                "flash_resizable_item_red" : index % 2 === 0 ? "flash_resizable_item_custom-prim" :
                                "flash_resizable_item_custom-second"
                                :
                                this.props.data?.competitor?.[index]?.fixed_status === "F" ?
                                "flash_resizable_item_finish"
                                :
                                ""
                            }`}
                            key={`resizable_item_content${index}`}
                            style={{background: `${index % 2 === 0 ? this.props.colors[0] : this.props.colors[1]}`}}
                        >
                            {
                                this.props.data?.competitor?.[index]?.best_lap_time === this.props.data?.race_info?.fastest?.best_lap_time &&
                                <div className="resizable_item_fastest_lap_mark" />
                            }
                            <div className="mobile__resizable_item_flexbox">
                            {
                                Object.keys(this.verticalModeDataType)?.map((field: any, index2: number) =>
                                    <>
                                        <div
                                            className="mobile__resiazble_item"
                                            key={`resizable_mobile_item_content${index2}$_${field}`}
                                            style={{
                                                width: `${this.verticalModeDataType[field].width}`,
                                                justifyContent: this.verticalModeDataType[field].justifyStart ?
                                                    "flex-start" : "center"
                                            }}
                                        >
                                        {
                                            this.verticalModeDataType[field].customElement ?
                                            this.verticalModeDataType[field].customElement(
                                                    {data: item[field],
                                                     fullData: item,
                                                     resultsByClass: this.props.resultsByClass,
                                                     resultsByClassPosAlt: field === "position" ? this.getPositionByClass(item[field]) : ""
                                                    }
                                                )
                                            :
                                            <p
                                                key={`${item[field]}_${index}`}
                                                style={{
                                                    marginLeft: this.verticalModeDataType[field].justifyStart ?
                                                        "10px": "0px"
                                                }}
                                            >{item[field]}
                                            </p>
                                        }
                                        </div>
                                    </>
                                )
                            }
                            </div>
                            <div className="mobile__resizable_item_percentage_container">
                                <div className="mobile__resizable_item_percentage">
                                {
                                    (item.percentage || item.percentage === 0) &&
                                    <div
                                        className="mobile__resizable_item_percentage_inner"
                                        style={{width: `${item.percentage}%`}}
                                    />
                                }
                                </div>
                            </div>
                        </div>
                    </>

                ) :
                // finish this for simpified results :)))
                this.state.groupedDataKeys.length === 0 ?
                <>
                    {
                        <div className="resizable_table_waiting_data">
                            <p className="animated-dots">Waiting for competitors</p>
                        </div>
                    }
                </>
                :
                this.state.groupedDataKeys.map((item: any, index: number) =>
                <>
                    {
                        index !== this.state.dragIndex
                        ?
                        !this.state.groupedData[item].every((element: any) => element === null) &&
                        <div
                            className="resiazble_content"
                            key={`resizeable_item_content${index}${item}`}
                            style={{width: `100%`}}
                        >
                            {this.state.groupedData[item].map((grouped_item: any, index2: number) =>
                                <>
                                    {
                                        this.props.resultsByClass &&
                                        this.firstOfEachClass &&
                                        this.firstOfEachClass.includes(this.props.data?.competitor?.[index2]?.nr) &&
                                        <div
                                            className={
                                                `resiazble_class_header`
                                            }
                                            style={{
                                                width: index + 1 !== this.state.groupedDataKeys?.length ?
                                                "calc(100% + 3px)" : "100%",
                                                height: "50px",
                                                background: "#232531",
                                                borderBlock: "1px solid #232531"
                                            }}
                                        >
                                            {index === 0 &&
                                                <div
                                                    className="resizable_class_header_decoration"
                                                    style={{
                                                        borderLeft: `2px solid ${classToColor(this.props.data?.competitor?.[index2]?.class, this.props.data?.race_info?.availableClasses)}`,
                                                        borderTop: `2px solid ${classToColor(this.props.data?.competitor?.[index2]?.class, this.props.data?.race_info?.availableClasses)}`
                                                    }}
                                                />
                                            }
                                            <p>{index === 0 ? this.props.data?.competitor?.[index2]?.class : ""}</p>
                                        </div>
                                    }
                                    <div
                                        className={`resizable_item`}
                                        onClick={() => this.addToLocalStorage(this.props.data?.competitor?.[index2]?.nr)}
                                        key={`grouped_item_${this.props.data?.competitor?.[index2]?.nr}${index2}${index}${item}`}
                                        style={{
                                                width: `${this.state.colwidths[item]}px`,
                                                background:
                                                    ["+", "-", "="].includes(this.props.data?.competitor?.[index2]?.flash_status) &&
                                                    this.props.data?.competitor?.[index2]?.lap > 0
                                                    ?
                                                    this.props.data?.competitor?.[index2]?.flash_status === "+" ? "#355e3e" :
                                                    this.props.data?.competitor?.[index2]?.flash_status === "-" ? "#7d2727" : "#2c3c5e"
                                                    :
                                                    this.state.selectedCompetitors?.includes(this.props.data?.competitor?.[index2]?.nr) ?
                                                    "#5b52f7" :
                                                    this.props.data?.competitor?.[index2]?.fixed_status === "F" ?
                                                    "#4a4c52" :
                                                    `${index2 % 2 === 0 ? this.props.colors[0] : this.props.colors[1]}`,
                                            }}
                                    >
                                        { this.parseItem(item, grouped_item, index2) }
                                    </div>
                                </>

                            )}
                        </div>
                        :
                        <div
                            className="resizable-ghost-content"
                            style={{ width: "100%" }}
                        >
                            <div className="resizable-ghost-content-inner"
                                style={{ width: `${this.state.colwidths[item]}px` }}
                            />
                        </div>
                    }
                </>
                )
                }
            </div>
        </div>
        );
    }
};