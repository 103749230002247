import { useEffect, useState } from "react";
import { FaFolderOpen } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import "./downloads.scss";
import { BoardTable } from "../../../../components/ScoreboardTable/ScoreboardTable";

const Stash = (props: any) => {

    const [folders, setFolders] = useState<any[]>([]);
    const [nameFilterValue, setNameFilterValue] = useState<string>("");
    const [dateFilterValue, setDateFilterValue] = useState<string>("desc");

    const navigate = useNavigate();

    const handleMessage = (event: any) => {
        const message = JSON.parse(event.data);
        switch (message.type) {
            case "folder_data_response": {
                setFolders(message.data);
                break;
            }
        }
    }

    const filteredActiveRaces = () => {
        let temp = folders;

        if (nameFilterValue) {
            temp = temp.filter((item: any) => {
                return item?.name?.toLowerCase().includes(nameFilterValue.toLowerCase());
            });
        }

        if (dateFilterValue) {
            temp = dateFilterValue === "asc" ? temp : [...temp].reverse();
        }

        return temp;
    };

    const navigateTo = (item: any) => {
        navigate(`/downloads/${item.name}`);
    };

    useEffect(() => {
        props.ws.send(JSON.stringify({ type: "folder_data" }));
        props.ws.addEventListener('message', handleMessage);
        return () => {
            props.ws.removeEventListener('message', handleMessage);
        }
    }, [props.ws]);

    return (
        <div className="app_stash">
            <div className="app_stash_container">
                <header><h2>Downloads</h2><p>Download official race results here.</p></header>
                <div className="app_stash_folders">
                <BoardTable
                    legend={[
                        {data: "name", dataParent: "", name: "Event", width: 75, minWidth: 200, img: false, center: false, customSrc: "/icons/", customElement: ''},
                        {data: "fileCount", dataParent: "", name: "Files", width: 10, minWidth: 50, img: false, center: false, customSrc: "", customElement: ''},
                        {data: "created", dataParent: "", name: "Created", width: 15, minWidth: 90, img: false, center: false, customSrc: "", customElement: ''},
                    ]}
                    data={{}}
                    properties={{filters: true, currentPageData: filteredActiveRaces(), maxPage: 1, dateFilterValue: dateFilterValue}}
                    functions={{
                        setNameFilterValue: setNameFilterValue, setDateFilterValue: setDateFilterValue
                    }}
                    customFunctions={{navigateTo: navigateTo, filters: () => {}}}
                />
                </div>
            </div>
        </div>
    )
}

export default Stash;