import React from 'react';
import { getRaceTime, flagToColor } from '../../helpers/Functions';
import { useNavigate } from 'react-router-dom';

// Icons import
import { RiFullscreenLine} from "react-icons/ri";
import { IoFilterOutline } from 'react-icons/io5';


interface ActiveRaceHeaderProps {
    activeRace: any;
    isTabletOrMobile: boolean;
    currentViewers: number;
    hash: string | any;
    openTab: Function;
    setShowFilters: Function;
};

const ActiveRaceHeader: React.FC<ActiveRaceHeaderProps> = (
        {
            activeRace, isTabletOrMobile, currentViewers, hash,
            openTab, setShowFilters
        }
    ) => {
    const navigate = useNavigate();

    const buttonConfig = {
        filter: { icon: IoFilterOutline, action: () => setShowFilters() },
        //settings: { icon: IoMdSettings, action: () => openTab("settings") },
        //datastream: { icon: ImConnection, action: () => openTab("datastream") }
    };

    return (
        <header>
            <div className={
                isTabletOrMobile ? "app_liveList-selected-track-name-mobile" : "app_liveList-selected-track-name"}>
                <p>{activeRace?.race_details?.name}</p>
                <span>{activeRace?.race_details?.championship}</span>
                <span
                    style={{color: isTabletOrMobile ? "#51d6ff" : "azure"}}
                >{activeRace?.race_details?.track_name}</span>
                {
                    isTabletOrMobile ?
                    <div className='track_mobile_live-details'>
                        <hr />
                        <div className='track_mobile_live-details-top'>
                            <hr />
                        </div>
                        <div className='track_mobile_live-details-item'>
                            <p>Track length</p>
                            <span>{`${activeRace?.race_details?.track_length} m`}</span>
                        </div>
                        <div className='track_mobile_live-details-item'>
                            <p>Race type</p>
                            <span>{`${activeRace?.race_details?.race_type}`}</span>
                        </div>
                    </div>
                    :
                    <span>{`Track length: ${activeRace?.race_details?.track_length} m`}</span>
                }
                {
                    !isTabletOrMobile &&
                    <div className='app_liveList-selected-track-type'>
                        <p>{activeRace?.race_details?.race_type}</p>
                    </div>
                }
            </div>
            <div className="app_liveList-selected-time">
                <p>{getRaceTime(activeRace)}</p>
            </div>
            <div
                className="app_liveList-selected-time-flag-line app_liveList-selected-time-flag-line-animated"
                style={{
                    '--flag-color': flagToColor(activeRace?.race_details?.flag),
                    background: 'var(--flag-color)'
                } as React.CSSProperties}
            />
            {!isTabletOrMobile &&
                <div className="app_liveList-selected-viewers">
                    <p>Now watching</p>
                    <span>{currentViewers}</span>
                </div>
            }
            {!isTabletOrMobile &&
                <button className="app_liveList-selected-fullscreen"
                    onClick={() => { navigate(`/live/full/${hash}`) }}
                >
                    Fullscreen
                    <RiFullscreenLine color="#ffffff" size={"20px"}/>
                </button>
            }
            <div className="app_liveList-selected-settings">
                {!isTabletOrMobile &&
                    Object.entries(buttonConfig).map(([field, config]) => (
                        <button className="app_liveList-selected-settings-btn" onClick={config.action}>
                            <config.icon color="#ffffff" size={"25px"} />
                        </button>
                    ))
                }
            </div>
        </header>
    );
};

export default ActiveRaceHeader;