import React from 'react';
import { BsTrophyFill } from "react-icons/bs";
import { RiTimerFlashFill } from "react-icons/ri";

export default class RaceHistoryRaceWinners extends React.Component <{data: any, mediaQuery: any}, {raceWinner: any, fastestLap: any, raceType: number | null}> {

    winnerTypes: string[] = ["winner", "fastest"];

    constructor(props: any) {
        super(props);
        this.state = {
            raceWinner: {},
            fastestLap: {},
            raceType: null,
        }
    }
    componentDidUpdate(prevProps: Readonly<{ data: any }>): void {
        if (this.props.data !== prevProps.data) {
            this.parseData(this.props.data);
        }
    }
    parseData(data: any): void {
        if (!data) { return; }

        // Combine firstname and lastname variables into single variable "name"
        // and convert it based on if competitor is a sidecar

        this.winnerTypes.forEach((type: string) => {
            if (data[type]) {
                const typeData = data[type];
                data[type].name = typeData.isSidecar ?
                `${typeData.firstname.slice(0, -1)} / ${typeData.lastname}` :
                typeData.competitor;
            }
        });

        this.setState({
            raceWinner: data.winner,
            fastestLap: data.fastest,
            raceType: data.race_type_id
        });
    }
    render() {
        if (!this.state.raceWinner) { return (<></>); }
        return (
            <div
                className={`${this.props.mediaQuery.mobile ?
                    "mobile__historyRace-data-race-winners" : "app__historyRace-data-race-winners"
                }`}
                style={{
                    flexDirection: this.props.mediaQuery.mobile ?
                        !this.props.mediaQuery.split ? "row" : "column"
                    : "row"
                }}
            >
            {
                <div className="app__historyRace-data-winner">
                    <BsTrophyFill color="#ffe608" size={"30px"}/>
                    <h4>{`${this.state.raceWinner?.name}`}</h4>
                    <p>{this.state.raceWinner?.class}</p>
                </div>
            }
            {
                this.state.raceType === 2 &&
                <div className="app__historyRace-data-fastest-lap">
                    <RiTimerFlashFill size={"32px"}/>
                    <h4>{`${this.state.fastestLap?.competitor}`}</h4>
                    <span>{this.state.fastestLap?.best_lap_time}</span>
                </div>
            }
            </div>
        );
    }
};