import "./mainPage.scss";
import { LiveList } from "./components/live/liveList";
import { SelectedLiveRace } from "./components/selectedLive/selectedLiveRace";
import { SelectedLiveFull } from "./components/selectedLiveFull/selectedLiveFull";
import { RaceHistoryGroups } from './components/history/raceHistoryGroups';
import { RaceHistoryEvent } from "./components/history/event/raceHistoryEvent";
import { RaceHistoryRace } from "./components/history/race/raceHistoryRace";
import Stash from "./components/downloads/stash";
import Folder from "./components/downloads/folder";
import { PositionCanvas, PositionCanvas_Test } from "./components/PositionCanvas";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Slider from "react-slick";
import { MdOutlineSignalWifiStatusbarConnectedNoInternet4 } from "react-icons/md";
import { AiOutlineMenu } from "react-icons/ai";
import { useMediaQuery } from 'react-responsive'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ReconnectingWebSocket from "./ws";

import { PiTelevisionFill } from "react-icons/pi";
import { MdOutlineHistory, MdOutlineDownloading, MdEmail } from "react-icons/md";
import { IoCloseSharp } from "react-icons/io5";
import { useRouteHistory } from "./context/RouteHistoryContext";

import { FaFacebookSquare, FaGithub } from "react-icons/fa";

export function getBackendServerAddr() {
    const USE_PROD_MODE = true;
    return USE_PROD_MODE ? "wss://stream.racegraphics.eu:3015" : "http://localhost:3015";
};

export const ImageCarousel: React.FC = () => {
    const settings = {
        className: "slider",
        arrows: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 5,
        slidesToScroll: 1,
    };
    const images: any[] = importAll(
        require.context("../../../public/supporters", false, /\.(png|jpe?g|svg)$/)
    );
    return (
        <div className="app__imageSlider">
            <Slider {...settings}>
            {images.map((image: string, index) => (
                <div className="app__imageSlider_img" key={`${index}_slider_img`}>
                    <img src={image} alt={`Image ${index}`} />
                </div>
            ))}
            </Slider>
        </div>
    );
};

function importAll(r: __WebpackModuleApi.RequireContext) {
    return r.keys().map(r);
};

let ws = new ReconnectingWebSocket(getBackendServerAddr());

export const MainPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 850px)" });
    const [isWsConnected, setIsWsConnected] = useState<boolean>(false);
    const [openMenu, setOpenMenu] = useState<boolean>(false);
    const { routeHistory, addRoute } = useRouteHistory();

    const handleNavigate = (route: string) => {
        addRoute(route);
        navigate(route);
        setOpenMenu(false);
    }

    useEffect(() => {
        const handleOpen = () => {
            setIsWsConnected(true);
        };
        const handleClose = () => {
            setIsWsConnected(false);
        };
        const handleError = (error: Event) => {
            setIsWsConnected(false);
        };

        // Add event listeners for WebSocket open and close events
        ws.addEventListener('open', handleOpen);
        ws.addEventListener('close', handleClose);
        ws.addEventListener('error', handleError);

        // Clean up event listeners on component unmount
        return () => {
            ws.removeEventListener('open', handleOpen);
            ws.removeEventListener('close', handleClose);
            ws.removeEventListener('error', handleError);
        };
    }, [ws]);

    return (
        <div
            className="app__live-main"
        >
            <nav
                className="app__navbar"
                style={{
                    background: isTabletOrMobile ? '#232531' : "#f4f4f4",
                    color: !isTabletOrMobile ? '#232531' : 'white'
                }}
            >
                {
                    isTabletOrMobile ?
                    <ul>
                        <li>
                            <div className="app__navbar-logo">
                                <img src="/logo_new.png" alt="" />
                            </div>
                        </li>
                        <li onClick={() => setOpenMenu((prev: boolean) => !prev)}>
                            {openMenu ? <IoCloseSharp size={"30px"}/> : <AiOutlineMenu size={"30px"}/>}
                        </li>
                    </ul>
                    :
                    <ul>
                        <li>
                            <div className="app__navbar-logo">
                                <img src="/logo_new.png" alt="" />
                            </div>
                        </li>
                        <li onClick={() => { handleNavigate("/") }}>
                            <p>Live Timing</p>
                            {location.pathname === "/" && <hr />}
                        </li>
                        <li onClick={() => { handleNavigate("/history") }}>
                            <p>Event History</p>
                            {location.pathname === "/history" && <hr />}
                        </li>
                        <li onClick={() => { handleNavigate("/downloads") }}>
                            <p>Download Results</p>
                            {location.pathname === "/downloads" && <hr />}
                        </li>
                    </ul>
                }
            </nav>
            {
                openMenu &&
                <div className="app__navbar_menu">
                    <div className="app__navbar_menu_inner">
                        <p>RaceOnline 2024 | v1.0</p>
                        <ul>
                            <li key="li1" style={{background: '#94dafb', color: '#232531'}}
                                onClick={() => { handleNavigate("/"); }}>
                                    <PiTelevisionFill size={"25px"}/><span>Live Timing</span></li>
                            <li key="li2" style={{background: '#ff9a62', color: '#232531'}}
                                onClick={() => { handleNavigate("/history");}}>
                                    <MdOutlineHistory size={"25px"}/><span>Event History</span></li>
                            <li key="li3" style={{background: '#c9a0ff', color: '#232531'}}
                                onClick={() => { handleNavigate("/downloads");}}>
                                    <MdOutlineDownloading size={"25px"}/><span>Download Results</span></li>
                        </ul>
                        {/*
                         TODO: For future this could be impemented to show recent opened races but needs more work
                         {routeHistory.length > 0 && (
                            <div className="app__navbar_recent_routes">
                                <p>You recenlty visited</p>
                                <ul>
                                    {routeHistory.map((route, index) => (
                                        <li key={`history-${index}`} onClick={() => handleNavigate(route)}>
                                            {route && route.length > 1 ? route?.substring(1) : "Home"}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )} */}
                    </div>
                </div>
            }
            {
                isWsConnected ?
                <Routes>
                    <Route path={"/"} element={<LiveList ws={ws} />} />
                    <Route path={"/history"} element={<RaceHistoryGroups ws={ws}/>} />
                    <Route path={"/history/:date/:track_name"} element={<RaceHistoryEvent ws={ws}/>} />
                    <Route path={"/history/:date/:track_name/:eventId"}
                        element={<RaceHistoryRace ws={ws}/>} />
                    <Route path={"/live/"} />
                    <Route path={"/live/:hash"} element={<SelectedLiveRace ws={ws}/>} />
                    <Route path={"/live/full/:hash"} element={<SelectedLiveFull ws={ws}/>} />
                    <Route path={"/downloads"} element={<Stash ws={ws} />} />
                    <Route path={"/downloads/:hash"} element={<Folder ws={ws} />} />
                    <Route path={"/test"} element={<PositionCanvas_Test />} />
                </Routes>
                :
                <div className="app__waiting_for_conn">
                    <div className="app__waiting_for_conn_inner">
                        <MdOutlineSignalWifiStatusbarConnectedNoInternet4 size={"60px"}/>
                        <h1>Hold tight</h1>
                        <p>Waiting for response from race server...</p>
                        <p>If this message appears for too long try refreshing the page</p>
                        <div className="lds-ring" style={{marginTop: "10px"}}><div></div><div></div><div></div><div></div></div>
                    </div>
                </div>
            }
            {
                !isTabletOrMobile &&
                <div className="app__sponsors_line-wrapped">
                    <div className="app__sponsors_line">
                        <ImageCarousel />
                    </div>
                </div>
            }
            {
                !isTabletOrMobile &&
                <footer className="app__live-footer">
                    <div className="app__live-footer-logo">
                        <img src="/logo_new.png" alt="" />
                        <span>Race Online</span>
                    </div>
                    <div className="app__live-footer-links">
                        <a href="https://motorparks.lv" target="_blank" rel="noopener noreferrer">Motorparks Timekeeping</a>
                        <a href="https://racegraphics.eu" target="_blank" rel="noopener noreferrer">Race Graphics</a>
                    </div>
                    <div className="app__live-footer-socials">
                        <span>2024 RaceOnline. All rights reserved</span>
                        <div className="app__live-footer-socials_icons">
                            <FaFacebookSquare size={"30px"} />
                            <MdEmail size={"30px"} />
                            <FaGithub size={"30px"} />
                        </div>
                        <span>Coded by Roberts Kalve</span>
                    </div>
                </footer>
            }

        </div>
    );
};