import React from 'react';
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";

const Paginator = ({ properties, functions }: any) => {
    const { currentPage, maxPage } = properties;
    const { currentPage: setCurrentPage } = functions;

    const getPages = () => {
        const pages = [];
        if (maxPage <= 5) {
            for (let i = 1; i <= maxPage; i++) {
                pages.push(i);
            }
        } else {
            if (currentPage <= 3) {
                pages.push(1, 2, 3, 4, maxPage);
            } else if (currentPage >= maxPage - 2) {
                pages.push(1, maxPage - 3, maxPage - 2, maxPage - 1, maxPage);
            } else {
                pages.push(1, currentPage - 1, currentPage, currentPage + 1, maxPage);
            }
        }
        return pages;
    };

    const renderDots = (prevPage: number, nextPage: number) => {
        if (nextPage - prevPage > 1) {
            return <span key={`dots-${prevPage}`}>...</span>;
        }
        return null;
    };

    return (
        <div className="app__historyGroups_pageChange">
            <div className="app__historyGroups_pageChange-inner">
                <button
                    className='page_change_arrows'
                    onClick={() => {
                        if (currentPage - 1 !== 0) {
                            setCurrentPage(currentPage - 1);
                        }
                    }}
                >
                    <MdNavigateBefore size={"30px"} />
                </button>
                <div className='page_change_pages'>
                    {getPages().map((page, index, array) => (
                        <React.Fragment key={index}>
                            {index > 0 && renderDots(array[index - 1], page)}
                            <button
                                onClick={() => setCurrentPage(page)}
                                className={page === currentPage ? 'active' : ''}
                            >
                                <p>{page}</p>
                            </button>
                        </React.Fragment>
                    ))}
                </div>
                <button
                    className='page_change_arrows'
                    onClick={() => {
                        if (currentPage + 1 <= maxPage) {
                            setCurrentPage(currentPage + 1);
                        }
                    }}
                >
                    <MdNavigateNext size={"30px"} />
                </button>
            </div>
        </div>
    );
};

export default Paginator;