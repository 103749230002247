import { useParams } from "react-router-dom";
import "./downloads.scss";
import { useEffect, useState } from "react";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { VscOpenPreview } from "react-icons/vsc";
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { BoardTable } from "../../../../components/ScoreboardTable/ScoreboardTable";

const FileIcon = () => {
    return (
        <img src="/icons/pdf.png" width="30px" height="30px" alt=""/>
    );
}

const FileActions = (file: any, customFn: any) => {
    return (
        <div className="app_stash_file_actions">
            <button title="Download" style={{background: "#9ae540"}} onClick={() => { customFn.requestFile(file.name); customFn.download(); }}><FaCloudDownloadAlt size={"20px"}/></button>
            <button title="Preview" style={{background: "#c9a0ff"}} onClick={() => { customFn.requestFile(file.name); customFn.setOpenPreview(true);}}><VscOpenPreview size={"20px"}/></button>
        </div>
    );
}

const Folder = (props: any) => {

    const {hash} = useParams();
    const [files, setFiles] = useState<any[]>([]);
    const [nameFilterValue, setNameFilterValue] = useState<string>("");
    const [dateFilterValue, setDateFilterValue] = useState<string>("desc");
    const [previewFile, setPreviewFile] = useState<any | null>(null);
    const [open, setOpenPreview] = useState<boolean>(false);
    const [callback, setCallback] = useState<(() => void) | null>(null);

    function requestFile(filename: string, cb?: () => void) {
        setCallback(() => cb);
        props.ws.send(JSON.stringify({
            type: 'file_data',
            path: hash,
            file: filename
        }));
    }

    function download() {
        if (!previewFile) return;
        const anchor = document.createElement('a');
        anchor.href = `data:application/octet-stream;base64,${previewFile?.fileData}`;
        anchor.download = previewFile?.filename;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    }

    const handleMessage = (event: any) => {
        const message = JSON.parse(event.data);
        switch (message.type) {
            case "subfolder_data_response": {
                const tmp: any = [];
                message.data?.forEach((item: string) => {
                    tmp.push({type: 'pdf', name: item, action: ''});
                });
                setFiles(tmp);
                break;
            }
            case "file_data_response": {
                if (!message.success) return;
                setPreviewFile(message);
                if (callback) {
                    callback();
                    setCallback(null);
                }
            }
        }
    }

    const filteredFiles = () => {
        let temp = files;

        if (nameFilterValue) {
            temp = temp.filter((item: any) => {
                return item?.name?.toLowerCase().includes(nameFilterValue.toLowerCase());
            });
        }

        if (dateFilterValue) {
            temp = dateFilterValue === "asc" ? temp : [...temp].reverse();
        }

        return temp;
    };


    useEffect(() => {
        props.ws.send(JSON.stringify({ type: "subfolder_data", path: hash}));
        props.ws.addEventListener('message', handleMessage);
        return () => {
            props.ws.removeEventListener('message', handleMessage);
        }
    }, [props.ws]);

    return (
        <div className="app_stash">
            <div className="app_stash_container">
                <header><h2>{hash}</h2></header>
                <div className="app_stash_folders">
                    <BoardTable
                        legend={[
                            {data: "type", dataParent: "", name: "Type", width: 10, minWidth: 50, img: false, center: false, customSrc: "", customElement: FileIcon},
                            {data: "name", dataParent: "", name: "Name", width: 75, minWidth: 200, img: false, center: false, customSrc: "/icons/", customElement: ''},
                            {data: "action", dataParent: "", name: "Action", width: 15, minWidth: 90, img: false, center: false, customSrc: "", customElement: FileActions},
                        ]}
                        data={{}}
                        properties={{filters: true, currentPageData: filteredFiles(), maxPage: 1, dateFilterValue: dateFilterValue}}
                        functions={{
                            setNameFilterValue: setNameFilterValue, setDateFilterValue: setDateFilterValue
                        }}
                        customFunctions={{navigateTo: () => {}, filters: () => {},
                            download: download, setOpenPreview: setOpenPreview, requestFile: requestFile}}
                    />
                </div>
                {open && previewFile && (
                    <div className="pdf-preview-wrapper">
                        <div className="pdf-preview">
                            <h3>{previewFile?.filename.replace('.pdf', '')}</h3>
                            <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
                                <div className="pdf-viewer-container">
                                    <Viewer fileUrl={`data:application/pdf;base64,${previewFile?.fileData}`} />
                                </div>
                            </Worker>
                            <div className="pdf-preview-actions">
                                <button onClick={() => setOpenPreview(false)}>Close Preview</button>
                                <button style={{background: "#46b146"}} onClick={() => download()}><FaCloudDownloadAlt size={"20px"}/>Download</button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Folder;