import { BsFillCircleFill } from "react-icons/bs";
import { FaFlagCheckered } from "react-icons/fa";
import { MdTimer } from "react-icons/md";
import { TbTriangleFilled, TbTriangleInvertedFilled } from "react-icons/tb";

function subtractTimeStrings(time1: string, time2: string) {
    try {
        // Parse the first time string
        const [hours1, minutes1, seconds1] = time1.split(':').map(Number);
        // Convert the first time to total seconds
        const totalSeconds1 = hours1 * 3600 + minutes1 * 60 + seconds1;
        //console.log(totalSeconds1, time1)
        // Parse the second time string
        const [minutes2 = 0, seconds2 = 0] = time2.split(':').map(Number);
        // Convert the second time to total seconds
        const totalSeconds2 = (minutes2 || 0) * 60 + (seconds2 || 0);
        // Subtract the second time from the first time and return the absolute value
        return Math.abs(totalSeconds1 - totalSeconds2);
    } catch (e) {
        console.log("could not split: " + time1 + " : " + time2);
        return 0;
    }
}

function getTimeElapsed(last_lap: string, current: string) {
    if (!last_lap) return 0;
    return subtractTimeStrings(current, last_lap);
}

function calculatePercantage(competitor: any, track_len: string, elapsed_time: string) {
    if (competitor.status === "F") return 100;
    if (competitor.lap < 1) return 0;
    if (!competitor.speed || !track_len) return "";
    const trackLenMeters = Number(track_len?.replace(/[.,]/g, ""));
    const timeElapsed = getTimeElapsed(competitor?.elapsed_time, elapsed_time);
    const metersCompleted = (competitor.speed / 3.6) * timeElapsed;

    let percentageCompleted = (metersCompleted / trackLenMeters) * 100;
    percentageCompleted = Math.round(Math.min(percentageCompleted, 100));

    return Number.isNaN(Number(percentageCompleted)) ? "" : percentageCompleted;
}


function darkenColor(hexColor: string, factor: number): string {
    const clamped = Math.min(1, Math.max(0, factor));

    let rgb = parseInt(hexColor.slice(1), 16); // Convert hex to rgb in one operation

    let r = (rgb >> 16) & 255;
    let g = (rgb >> 8) & 255;
    let b = rgb & 255;

    // Darken
    r = Math.round(r * (1 - clamped));
    g = Math.round(g * (1 - clamped));
    b = Math.round(b * (1 - clamped));

    // Back to hex
    return `#${((r << 16) | (g << 8) | b).toString(16).padStart(6, '0')}`;
}

function getRaceTime(data: any): any {
    if (!data) {
        return "";
    }

    const { flag, laps, race_type_id } = data?.race_details || {};
    const { left_time, elapsed_time } = data?.race_time || {};

    if (flag === "Finish") {
        return "FINISH";
    }

    if (flag === "None") {
        return "RACE OVER";
    }

    if (laps === 1) {
        return "FINAL LAP";
    }

    const isLaps = laps !== "9999";
    const name = isLaps ? "LAPS " : (race_type_id === 2 ? "TO GO " : "TIME TO GO");
    const time = left_time !== "00:00:00" ? left_time : elapsed_time;
    const dataStr = time?.startsWith("00:") ? time?.slice(3) : time;

    return (
        <span className="fullscreen_timer">
            <MdTimer size={"23px"} /> {name} : {isLaps ? laps : dataStr}
        </span>
    );
};

function flagToColor(flag: string): string {
    switch (flag) {
        case "None": return "#201f2d";
        case "Green":   return "#47ba79";
        case "Yellow":  return "#f7e705";
        case "Red":     return "#ed4747";
        case "Finish":  return "repeating-conic-gradient(#f2f0f0 0% 25%, #000000 0% 50%) 50% / 4px 4px";
        case "Warmup":  return "#b205f7";
        default: return "#000000";
    };
};

function getPositionArrow(positionChange: string, size = 18) {
    if (!positionChange) return;

    switch (positionChange) {
      case "+": return <TbTriangleFilled size={size} color={"#2dcc30"}/>
      case "-": return <TbTriangleInvertedFilled size={size} color={"#ff3636"}/>
      case "F": return <FaFlagCheckered size={20} color="azure" />//<img className="finish_flag" src={"/flags/finish_small.png"} alt="-" />
      case "=": return <BsFillCircleFill size={10} color={"#FFF"}/>
      default:  return <></>
    }
};

const colors = [
    "#f8a000", "#1e90ff", "#fa46a7", "#3fd95b", "#e6e5e5", "#e33e2b",
    "#a329c2", "#c2294c", "#cfc61d", "#cf701d", "#4d4d4d"
];

function classToColor(className: string, classArray: string[]): string {
    const index = classArray.indexOf(className);
    return colors[index] || "green";
}

export { getRaceTime, flagToColor, getPositionArrow, darkenColor, classToColor, calculatePercantage };