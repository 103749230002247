import React, { createContext, useState, useContext, ReactNode } from 'react';

interface RouteHistoryContextProps {
    routeHistory: string[];
    addRoute: (route: string) => void;
}

const RouteHistoryContext = createContext<RouteHistoryContextProps | undefined>(undefined);

export const RouteHistoryProvider = ({ children }: { children: ReactNode }) => {
    const [routeHistory, setRouteHistory] = useState<string[]>([]);

    const addRoute = (route: string) => {
        setRouteHistory(prevHistory => {
            const newHistory = [...prevHistory, route];
            return newHistory.length > 5 ? newHistory.slice(1) : newHistory; // Keep only the last 5 routes
        });
    };

    return (
        <RouteHistoryContext.Provider value={{ routeHistory, addRoute }}>
            {children}
        </RouteHistoryContext.Provider>
    );
};

export const useRouteHistory = () => {
    const context = useContext(RouteHistoryContext);
    if (!context) {
        throw new Error('useRouteHistory must be used within a RouteHistoryProvider');
    }
    return context;
};