import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import {CategoryScale, Legend} from 'chart.js';
import 'chartjs-adapter-date-fns'; // Import the date adapter
import { Socket } from 'socket.io-client';

interface PacketGraphProps {
    socket: Socket;
}

Chart.register(CategoryScale);

const PacketGraph: React.FC<PacketGraphProps> = ({ socket }) => {
    const [packets, setPackets] = useState<{ time: number; size: number }[]>([]);
    const warningTimeout = useRef<NodeJS.Timeout | null>(null);
    const chartRef = useRef<HTMLCanvasElement | null>(null);

    useEffect(() => {
        const ctx = chartRef.current?.getContext('2d');
        const chart = new Chart(ctx!, {
            type: 'line',
            data: {
                labels: [],
                datasets: [
                    {
                        label: 'Received packets',
                        data: [],
                        borderColor: '#4962ff',
                        backgroundColor: '#4ff8c4',
                        fill: false,
                        spanGaps: true,
                    },
                ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        type: 'time',
                        time: {
                            unit: 'second',
                        },
                        grid: {
                            color: '#333645',
                        },
                    },
                    y: {
                        beginAtZero: true,
                    },
                },
                plugins: {
                    legend: {
                        display: false
                    }
                },
            }
        });

        return () => {
            chart.destroy();
        };
    }, []);

    useEffect(() => {
        const updateChart = () => {
            const chart = Chart.getChart(chartRef.current!);
            if (chart) {
                chart.data.labels = packets.map((packet) => new Date(packet.time));
                chart.data.datasets[0].data = packets.map((packet) => packet.size);
                chart.update();
            }
        };

        updateChart();
    }, [packets]);

    useEffect(() => {
        const handleData = async (data: any) => {
            try {
                const packetSize = JSON.stringify(data).length;
                const newPacket = { time: Date.now(), size: packetSize };
                setPackets((prevPackets) => {
                    const updatedPackets = [...prevPackets, newPacket];
                    return updatedPackets.slice(-20); // Keep only the last 20 packets
                });

                if (warningTimeout.current) {
                    clearTimeout(warningTimeout.current);
                }

                warningTimeout.current = setTimeout(() => {
                    alert('No packets received for some time!');
                }, 5000); // 5 seconds timeout
            } catch (err) {
                console.log(err);
            }
        };

        socket.on('activeRaceData', handleData);

        return () => {
            socket.off('activeRaceData', handleData);
            if (warningTimeout.current) {
                clearTimeout(warningTimeout.current);
            }
        };
    }, [socket]);

    return (
        <div className='packet_monitor'>
            <canvas className="packet_monitor_canvas" ref={chartRef}></canvas>
        </div>
    );
};

export default PacketGraph;