import "../history/race/raceHistoryRace.scss";
import { useState } from 'react';
import { GiFullMotorcycleHelmet } from "react-icons/gi";
import { classToColor } from "../../../../helpers/Functions";

const generateLapsArray = (raceCompetitorsList: any, flag: string) => {
    const hasCompetitorWithMoreThanOneLap = raceCompetitorsList?.find((competitor: any) => competitor.lap > 0);

    if (!hasCompetitorWithMoreThanOneLap) {
        return [];
    }

    const leaderLaps = raceCompetitorsList[0].lap;

    let initialLapsArray = [];
    for (let i = leaderLaps; i > 1 && initialLapsArray.length < 3; i--) {
        initialLapsArray.push(Math.floor(i));
    }

    let filteredLapsArray = initialLapsArray.filter(lap =>
        raceCompetitorsList.some((competitor: any) => competitor.lap === lap.toString())
    );

    if (flag === "finish") {
        const maxLap = Math.max(...filteredLapsArray);
        filteredLapsArray = filteredLapsArray.filter(lap => lap <= maxLap);
    }

    return filteredLapsArray;
};

const groupCompetitors = (competitors: any[], threshold: number) => {
    const groups: any[] = [];
    competitors.forEach(competitor => {
        if (competitor.status === "F") return;
        let added = false;
        for (let group of groups) {
            if (Math.abs(group[0].percentage - competitor.percentage) <= threshold) {
                group.push(competitor);
                added = true;
                break;
            }
        }
        if (!added) {
            groups.push([competitor]);
        }
    });
    return groups;
};

const Simulator = ({ activeRace }: any) => {
    const [hoveredGroup, setHoveredGroup] = useState<{ lapIndex: number, groupIndex: number } | null>(null);
    const lapsArray = generateLapsArray(activeRace?.race_competitors_list || [], activeRace?.race_details?.flag);

    const selectedCompetitors = JSON.parse(localStorage.getItem("selected_start_nr") || '[]');

    if (activeRace?.race_details?.race_type_id !== 2) return null;
    if (["None", "Warmup"].includes(activeRace?.race_details?.flag)) return null;
    if (lapsArray?.length === 0) return null;

    return (
        <div className="app_simulator">
            {
                activeRace?.race_competitors_list && lapsArray?.length > 0 ?
                <>
                    <div className="app_simulator_head"></div>
                    <div className="app_simulator_content">
                        {
                            lapsArray.reverse().map((lap: any, index: number) => {
                                const competitorsInLap = activeRace?.race_competitors_list?.filter((competitor: any) => competitor.lap === lap.toString());
                                const groupedCompetitors = groupCompetitors(competitorsInLap, 5); // Adjust threshold as needed

                                return (
                                    <div key={`simulator_lap_${index}`} className="app_simulator_lap" style={{width: `${100 / lapsArray.length - 2}%`}}>
                                        <div className="app_simulator_lap_number"><p><span>{`LAP ${lap + 1}`}</span></p></div>
                                        <div
                                            className="app_simulator_lap_line"
                                        >
                                            <>
                                            {activeRace?.race_details?.flag === "Finish" ? <div className="app_simulator_finish_flag"/> : <span/>}
                                            {
                                                groupedCompetitors.map((group, groupIndex) => {
                                                    const leftPosition = group[0].percentage;
                                                    const isSelectedGroup = group.some((competitor: any) => selectedCompetitors.includes(competitor.nr));

                                                    if (group.length === 1) {
                                                        const competitor = group[0];
                                                        const isSelected = selectedCompetitors.includes(competitor.nr);
                                                        return (
                                                            <div
                                                                className="app_simulator_competitor"
                                                                key={`sim_competitor_${group[0].nr}`}
                                                                style={{
                                                                    left: `${leftPosition}%`,
                                                                    background: isSelected ? '#5b52f7' : classToColor(competitor.class, activeRace?.race_details?.availableClasses)
                                                                }}
                                                            >
                                                                <div className="app_simulator_competitor_inner">
                                                                    {competitor.nr}
                                                                </div>
                                                            </div>
                                                        );
                                                    }

                                                    return (
                                                        <div
                                                            className="app_simulator_competitor_group"
                                                            key={`sim_competitor_group_${group.map((competitor: any) => competitor.nr).join('_')}_${groupIndex}`}
                                                            style={{
                                                                position: "absolute",
                                                                left: `${leftPosition}%`,
                                                                zIndex: activeRace?.race_competitors_list?.length * 2 - index + 1,
                                                                background: isSelectedGroup ? '#5b52f7' : 'white',
                                                                color: isSelectedGroup ? 'white' : '#111a28'
                                                            }}
                                                            onMouseEnter={() => setHoveredGroup({ lapIndex: index, groupIndex })}
                                                            onMouseLeave={() => setHoveredGroup(null)}
                                                        >
                                                            < GiFullMotorcycleHelmet size={20}/>
                                                            <p>{group.length > 1 ? `${group.length}` : group[0].nr}</p>
                                                            {
                                                                hoveredGroup?.lapIndex === index && hoveredGroup?.groupIndex === groupIndex &&
                                                                <div className="app_simulator_tooltip">
                                                                    {group.reverse().map((competitor: any) => (
                                                                        <div
                                                                            className="app_simulator_tooltip_competitor"
                                                                            key={`tooltip_competitor_${competitor.nr}`}
                                                                            style={{background: classToColor(competitor.class, activeRace?.race_details?.availableClasses)}}
                                                                        >
                                                                            {competitor.nr}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            }
                                                        </div>
                                                    );
                                                })
                                            }
                                            </>
                                        </div>
                                    </div>)
                            })
                        }
                    </div>
                    <p className="app_simulator_disclamer">Disclaimer: Competitors approximate position is being calulated by it's performance in last lap, no GPS data is being used.</p>
                </>
                :
                <p>No data</p>
            }

        </div>
    );
}

export default Simulator;