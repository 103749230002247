import "./ScoreboardTable.scss";
import { BsSortUpAlt, BsSortDownAlt, BsFilterLeft } from "react-icons/bs";
import { AiOutlineFileSearch } from "react-icons/ai";
import { IoIosSearch } from "react-icons/io";
import Paginator from "./Paginator";

export interface BoardField {
    data: string;
    dataParent: string;
    name: string;
    width: number;
    minWidth: number;
    img: boolean;
    center: boolean;
    customSrc: string;
    customElement: any;
    color: string | null;
};

export interface BoardTableProps {
    legend: any[];
    data: any;
    properties: any; // You can specify a more specific type if needed
    functions: any;
    customFunctions: any;
}

const insertLegend = (array: any, legendBar: any, legendItems: any) => {
    array.forEach((item: any, index: number) => {
        legendBar.push(
            <div
                className="__board_table_legendField"
                key={`${index}_board_table_field_legend`}
                style={{
                    width : `calc(${item.width}% - 5px)`,
                    minWidth: `${item.minWidth}px`,
                    justifyContent: "flex-start"
                }}
            >
            <p>{item.name}</p>
            </div>
        )
        legendItems.push(item);
    })
};

const FilterBar = ({ properties, functions, customFunctions }: any) => {
    return (
        <div className="__board_table_filter">
            <div className="__board_table_filter_items">
                <BsFilterLeft color="#5b52f7" size={"35px"} />
                <div className="__board_table_filter_name_filter">
                    <input
                        type="text"
                        id="board_table_event_name_input"
                        onChange={(event) => {
                            customFunctions.filters({ name: event.target.value });
                            functions.setNameFilterValue(event.target.value);
                        }}
                        placeholder={"Search for race..."}
                    />
                    <IoIosSearch size={"25px"} className="search-icon" />
                </div>
                <hr />
                <div className="__board_table_date_sorters">
                    <button
                        value={"asc"}
                        style={{ background: properties.dateFilterValue === "asc" ? "#5b52f7" : "#96a6c2" }}
                        onClick={() => functions.setDateFilterValue("asc")}
                    >
                        <BsSortUpAlt size={"25px"} />
                    </button>
                    <button
                        value={"desc"}
                        style={{ background: properties.dateFilterValue === "desc" ? "#5b52f7" : "#96a6c2" }}
                        onClick={() => functions.setDateFilterValue("desc")}
                    >
                        <BsSortDownAlt size={"25px"} />
                    </button>
                    <span>Sort by date</span>
                </div>
            </div>
        </div>
    );
};


const TableData = ({ properties, customFunctions, legendItems }: any) => {

    // If we dont get data about max page dont load table as it could break it
    if (!properties.maxPage && properties.maxPage !== 0) {
        return (
            <div className="__board_table_data_loading">
                <div className="lds-ring" style={{ marginTop: "10px" }}>
                    <div></div><div></div><div></div><div></div>
                </div>
            </div>
        );
    }

    // Placeholder when there is no data to display
    if (!properties.currentPageData || properties.currentPageData.length === 0) {
        return (
            <div className="__board_table_no_data">
                <AiOutlineFileSearch size={"60px"} />
                <h1>Oops</h1>
                <p>It looks like there is nothing to show here at the moment.</p>
            </div>
        );
    }

    // OK to display
    return (
        <div className="__board_table_data">
            {properties.currentPageData.map((item: any, index: number) => (
                <div
                    className="__board_table_data_item"
                    key={`${index}_board_table_data_item`}
                    onClick={() => customFunctions.navigateTo(item)}
                >
                    {legendItems.map((item_name: any, index2: number) => (
                        <p
                            style={{
                                maxWidth: `calc(${item_name.width}% - 5px)`,
                                width: '100%',
                                color: item_name?.color || "white",
                                minWidth: `calc(${item_name.minWidth}px - 5px)`,
                            }}
                            key={`${index}${index2}_race_hist_dataItem`}
                        >
                            {item_name.img && item?.[item_name?.data] ? (
                                <img
                                    style={{ width: "35px", height: "26px" }}
                                    src={`https://flagcdn.com/${item?.[item_name.data]?.toLowerCase()}.svg`}
                                />
                            ) : item_name.customElement ? (
                                item_name.customElement(item, customFunctions)
                            ) : (
                                <span>
                                    {item_name.dataParent ? item?.[item_name?.dataParent]?.[item_name?.data] : item?.[item_name?.data]}
                                </span>
                            )}
                        </p>
                    ))}
                </div>
            ))}
        </div>
    );
}


export const BoardTable: React.FC<BoardTableProps> = ({legend, data, properties, functions, customFunctions}) => {

    const legendBar: any = [];
    const legendItems: any = [];
    insertLegend(legend, legendBar, legendItems);

    return (
        <div className="__board_table_wrapped">
            {properties.filters && (
                <FilterBar properties={properties} functions={functions} customFunctions={customFunctions} />
            )}
            <div className="__board_table">
                <div className="__board_table_legend">
                    {legendBar}
                </div>
                <TableData properties={properties} customFunctions={customFunctions} legendItems={legendItems} />
            </div>
            {
                properties.maxPage && properties.pageSwitch ?
                <Paginator properties={properties} functions={functions}/>
                :
                <div className="app__historyGroups_pageChange"/>
            }
        </div>
    );
};