import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import "./raceHistoryEvent.scss";
import { parseDate } from '../../../../../helpers/Parsers';
import { BoardTable } from '../../../../../components/ScoreboardTable/ScoreboardTable';
import { raceTypeElement } from '../../live/liveList';

export const RaceHistoryEvent = (props: any) => {

    const navigate = useNavigate();
    const {date, track_name} = useParams();
    const [eventData, setEventData] = useState<any>([]);

    const [nameFilterValue, setNameFilterValue] = useState<string>("");
    const [dateFilterValue, setDateFilterValue] = useState<string>("desc");

    const searchFilters = () => {}

    const filteredData = () => {
        let temp = eventData;

        if (nameFilterValue) {
            temp = temp.filter((item: any) => {
                const raceTypeMatch = item?.race_type?.toLowerCase().includes(nameFilterValue.toLowerCase());
                const nameMatch = item?.name?.toLowerCase().includes(nameFilterValue.toLowerCase());
                return raceTypeMatch || nameMatch;
            });
        }

        if (dateFilterValue) {
            temp = dateFilterValue === "asc" ? temp : [...temp].reverse();
        }

        return temp;
    }

    const handleMessage = (event: any) => {
        const message = JSON.parse(event.data);
        switch (message.type) {
            case "event_history_date_response": {
                message.resp?.data?.forEach((item: any) => {
                    item.parsed_date = parseDate(item?.date);
                });
                setEventData(message.results);
                break;
            }
            default: console.log("No event provided!");
        }
    }

    const navigateTo = (item: any) => {
        navigate(`/history/${date}/${track_name}/${item.id}`);
    };

    useEffect(() => {
        if (!date || !track_name) { return; } // add some 404 page that says race not found or deleted
        props.ws.send(JSON.stringify({ type: "event_history_date", date: date, track: track_name }));
        props.ws.addEventListener('message', handleMessage);
        return () => {
            props.ws.removeEventListener('message', handleMessage);
        }
    }, [props.ws, track_name, date]);

    return (
        <div
            className="app__historyEvent-main"
        >
            <header>
                <h3>{track_name}</h3>
                <p>{parseDate(date!)}</p>
            </header>

            <BoardTable
                legend={[
                    {data: "race_type", dataParent: "", name: "Type", width: 10, minWidth: 100, img: false, center: true, customSrc: "", customElement: raceTypeElement},
                    {data: "name", dataParent: "", name: "Name", width: 70, minWidth: 220, img: false, center: false, customSrc: "", customElement: ""},
                    {data: "start_time", dataParent: "", name: "Start Time", width: 10, minWidth: 100, img: false, center: false, customSrc: "", customElement: ""},
                    {data: "end_time", dataParent: "", name: "End Time", width: 10, minWidth: 100, img: false, center: false, customSrc: "", customElement: ""},
                ]}
                data={{}}
                properties={{filters: true, navRunHistory: true, currentPageData: filteredData(), maxPage: 1,
                    dateFilterValue: dateFilterValue, nameFilterValue: nameFilterValue,
                }}
                functions={{setNameFilterValue: setNameFilterValue, setDateFilterValue: setDateFilterValue}}
                customFunctions={{navigateTo: navigateTo, filters: searchFilters}}
            />
        </div>
    );
};