import "./liveList.scss";
import { useEffect, useState } from "react";
import { BoardTable } from "../../../../components/ScoreboardTable/ScoreboardTable";
import { useNavigate } from "react-router-dom";
import { FaFlag, FaFlagCheckered } from "react-icons/fa6";

interface raceTypeData {
    race_type_name: string;
    race_type_id: number;
}
interface raceInfoElement {
    token: string;
    race_name: string;
    track_name: string;
    id: number;
    laps: number;
    flag: string;
    elapsed_time: string;
    race_type_data: raceTypeData;
};


export function raceTypeElement (props: any) {

    function getBg (type: string) {
        switch (type) {
            case "Qualification": return "#fede67";
            case "Race": return "#b6f26a";
            default: return "#ff9a62";
        }
    }
    return (
        <div
            className="__board_table_race_type"
            style={{background: getBg(props.race_type)}}
        >
            <p>{props.race_type}</p>
        </div>
    );
}


export const LiveList = (props: any) => {
    const [activeRaces, setActiveRaces] = useState<raceInfoElement[]>([]);
    const navigate = useNavigate();
    const [nameFilterValue, setNameFilterValue] = useState<string>("");
    const [dateFilterValue, setDateFilterValue] = useState<string>("desc");

    function flagToColor(flag: string): string {
        switch (flag) {
            case "None": return "#181921";
            case "Green":   return "#47ba79";
            case "Yellow":  return "#f7e705";
            case "Red":     return "#ed4747";
            case "Finish":  return "repeating-conic-gradient(#f2f0f0 0% 25%, #181921 0% 50%) 50% / 4px 4px";
            case "Warmup":  return "#b205f7";
            default: return "#181921";
        };
    };

    const getFlagElement = (item: any) => {
        return (
            <div className="__board_table_flag_element">
                {
                    item?.flag === "Finish" ?
                    <FaFlagCheckered color="white" size={"23px"}/>
                    :
                    <FaFlag color={flagToColor(item?.flag)} size={"23px"}/>
                }
            </div>
        );
    };

    const filteredActiveRaces = () => {
        let temp = activeRaces;

        if (nameFilterValue) {
            temp = temp.filter((item: any) => {
                const trackNameMatch = item?.track_name?.toLowerCase().includes(nameFilterValue.toLowerCase());
                const raceNameMatch = item?.championship?.toLowerCase().includes(nameFilterValue.toLowerCase());
                return trackNameMatch || raceNameMatch;
            });
        }

        if (dateFilterValue) {
            temp = dateFilterValue === "asc" ? temp : [...temp].reverse();
        }

        return temp;
    };

    const RaceServerElement = (item: any) => {
        return (
            <div className="__board_table_race_server">
                <p><span><h4></h4></span>{`Server ${item?.subscriber_id}`}</p>
            </div>
        );
    }

    const navigateTo = (item: any) => {
        navigate(`/live/${item.ctrl}`);
    };

    const activeRacesEvent = (event: any) => {
        const message = JSON.parse(event.data);
        if (message.type === "active_events_response") {
            // message?.events?.forEach((event: any) => {
            //     if (event.elapsed_time) {
            //         console.log(event.elapsed_time);
            //         event.elapsed_time = Object.values(event.elapsed_time)?.[0];
            //     }
            // });
            setActiveRaces(message.events);
        }
    }

    useEffect(() => {
        props.ws.send(JSON.stringify({type: "active_events"}));
        props.ws.addEventListener('message', activeRacesEvent);
        return () => {
            props.ws.removeEventListener('message', activeRacesEvent);
        }
    }, []);

    return (
        <div
            className="app_liveList-main"
        >
            <header>
                <h1>Races Online</h1>
                <p>See whats happening right now and view live results.</p>
            </header>
            <BoardTable
                legend={[
                    {data: "subscriber_id", dataParent: "", name: "Race server", width: 13, minWidth: 110, img: false, center: false, customSrc: "/icons/", customElement: RaceServerElement},
                    {data: "race_type", dataParent: "", name: "Run Type", width: 10, minWidth: 100, img: false, center: false, customSrc: "", customElement: raceTypeElement},
                    {data: "flag", dataParent: "", name: "Flag", width: 7, minWidth: 80, img: false, center: false, customSrc: "", customElement: getFlagElement},
                    {data: "track_name", dataParent: "", name: "Track Name", width: 20, minWidth: 180, img: false, center: false, customSrc: "", customElement: ""},
                    {data: "championship", dataParent: "", name: "Event Name", width: 40, minWidth: 200, img: false, center: false, customSrc: "", customElement: ""},
                    {data: "elapsed_time", dataParent: "", name: "Until finish", width: 10, minWidth: 120, img: false, center: false, customSrc: "", customElement: ""},
                ]}
                data={{}}
                properties={{filters: true, currentPageData: filteredActiveRaces(), maxPage: 1, dateFilterValue: dateFilterValue}}
                functions={{
                    setNameFilterValue: setNameFilterValue, setDateFilterValue: setDateFilterValue
                }}
                customFunctions={{navigateTo: navigateTo}}
            />
        </div>
    );
};