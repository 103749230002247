
import { IoSettings } from 'react-icons/io5';
import React, { useState, useEffect} from 'react';


interface CheckboxItemProps {
    label: string;
    id: number;
    onCheckboxChange: () => void;
}

const CheckboxItem: React.FC<CheckboxItemProps> = ({ label, id, onCheckboxChange }) => {

    const [checked, setChecked] = useState<boolean>(false);

    const changeCheckState = () => {
        setChecked(!checked);
        const saved = localStorage.getItem("checkboxStates");
        const checkboxStates = saved ? JSON.parse(saved) : {};
        checkboxStates[id] = !checked;
        localStorage.setItem("checkboxStates", JSON.stringify(checkboxStates));
        onCheckboxChange();
    };

    useEffect(() => {
        const saved = localStorage.getItem("checkboxStates");
        if (!saved) {
            localStorage.setItem("checkboxStates", JSON.stringify({1: true, 2: true}));
        }
        const checkboxStates = saved ? JSON.parse(saved) : {1: true, 2: true};
        if (checkboxStates?.[id]) {
            setChecked(checkboxStates?.[id]);
        }
    }, []);

    return (
        <div
            className="app_liveList-settings-item"
            key={`settings_checkbox_item_${id}${label}`}
        >
            <span>{label}</span>
            <input type="checkbox" className="app_liveList-settings-item-checkbox" checked={checked} onChange={() => changeCheckState()} />
        </div>
    );
};


interface ButtonProps {
    onClick: () => void;
    children: React.ReactNode;
}

const ResetButton: React.FC<ButtonProps> = ({ onClick, children }) => (
    <button onClick={onClick}>{children}</button>
);


interface SettingsProps {
    callback: Function;
}

const SettingsSelector: React.FC<SettingsProps> = ({ callback }) => {
    
    const resetTableSettings = () => {
        localStorage.removeItem("colwidths_live");
        localStorage.removeItem("colorder_live");
        window.location.reload();
    };

    return (
        <div className="app_liveList-settings-selector">
            <div className="app_liveList-settings-group">
                <header><IoSettings />Accessories Settings</header>
                <div className="app_liveList-settings-items">
                    <CheckboxItem label="Race winner bar" id={1} onCheckboxChange={() => callback()}/>
                    <CheckboxItem label="Top 3 leader bar" id={2} onCheckboxChange={() => callback()}/>
                    <CheckboxItem label="-" id={3} onCheckboxChange={() => callback()}/>
                    <CheckboxItem label="-" id={4} onCheckboxChange={() => callback()}/>
                    <CheckboxItem label="-" id={5} onCheckboxChange={() => callback()}/>
                    <CheckboxItem label="-" id={6} onCheckboxChange={() => callback()}/>
                </div>
            </div>
            <div className='app__livelist-settings-buttons'>
                <ResetButton onClick={resetTableSettings}>Reset table settings</ResetButton>
            </div>
        </div>
    );
};

export default SettingsSelector;