class ReconnectingWebSocket {
    private url: string;
    private ws: WebSocket | null = null;
    private reconnectInterval: number;
    private reconnectTimeout: number | null = null;
    private messageListeners: ((event: MessageEvent) => void)[] = [];
    private eventListeners: { [key: string]: EventListener[] } = {};

    constructor(url: string, reconnectInterval = 1500) {
        this.url = url;
        this.reconnectInterval = reconnectInterval;
        this.connect();
    }

    private connect() {
        this.ws = new WebSocket(this.url);

        this.ws.addEventListener('open', this.onOpen);
        this.ws.addEventListener('close', this.onClose);
        this.ws.addEventListener('error', this.onError);
        this.ws.addEventListener('message', this.onMessage);

        // Reattach custom event listeners
        Object.keys(this.eventListeners).forEach(type => {
            this.eventListeners[type].forEach(listener => {
                this.ws?.addEventListener(type, listener);
            });
        });
    }

    private onOpen = () => {
        if (this.reconnectTimeout) {
            clearTimeout(this.reconnectTimeout);
            this.reconnectTimeout = null;
        }
    };

    private onClose = () => {
        this.reconnect();
    };

    private onError = (error: Event) => {
        console.error('WebSocket error:', error);
        this.reconnect();
    };

    private onMessage = (event: MessageEvent) => {
        this.messageListeners.forEach(listener => listener(event));
    };

    private reconnect() {
        if (this.reconnectTimeout) return;
        this.reconnectTimeout = window.setTimeout(() => {
            this.connect();
        }, this.reconnectInterval);
    }

    public send(data: string) {
        if (this.ws && this.ws.readyState === WebSocket.OPEN) {
            this.ws.send(data);
        } else {
            console.error('WebSocket is not open. Ready state:', this.ws?.readyState);
        }
    }

    public addMessageListener(listener: (event: MessageEvent) => void) {
        this.messageListeners.push(listener);
    }

    public removeMessageListener(listener: (event: MessageEvent) => void) {
        this.messageListeners = this.messageListeners.filter(l => l !== listener);
    }

    public addEventListener(type: string, listener: EventListener) {
        if (!this.eventListeners[type]) {
            this.eventListeners[type] = [];
        }
        this.eventListeners[type].push(listener);
        this.ws?.addEventListener(type, listener);
    }

    public removeEventListener(type: string, listener: EventListener) {
        if (this.eventListeners[type]) {
            this.eventListeners[type] = this.eventListeners[type].filter(l => l !== listener);
        }
        this.ws?.removeEventListener(type, listener);
    }
}

export default ReconnectingWebSocket;